import styled from 'styled-components'

export const Cropper = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
`

export const Avatar = styled.img`
  height: 100%;
  margin: -15px 0px 0px 0px;
  border-bottom: 1px solid #505050;
`
