import styled from 'styled-components'

export const Info = styled.span`
  font-size: 20px;
  line-height: 30px;
  margin-top: 15px;
  width: 30%;
  text-align: center;
  @media (min-width: 320px) and (max-width: 1300px) {
    width: 85%;
  }
`
