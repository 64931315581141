import * as S from './styles'
const avatar = require('./avatar.jpeg')

const Avatar = () => (
  <S.Cropper>
    <S.Avatar src={avatar} />
  </S.Cropper>
)

export default Avatar
