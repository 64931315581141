import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding: 50px 100px;
  @media (min-width: 320px) and (max-width: 1300px) {
    padding: 0;
    margin-top: 50px;
  }
  span {
    font-size: 20px;
  }
`
