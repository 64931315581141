import Typewriter from 'typewriter-effect'

import { Avatar, Card, Info, Layout, Social } from './components'

const Home = () => (
  <Layout>
    <Card>
      <Avatar />
      <Typewriter
        onInit={(typewriter) => {
          typewriter
            .pauseFor(300)
            .typeString("Hi, I'm Bruno.")
            .pauseFor(1000)
            .deleteChars(6)
            .typeString('a JS lover')
            .pauseFor(200)
            .deleteChars(8)
            .typeString('gamer')
            .pauseFor(200)
            .deleteAll()
            .typeString('Welcome to my site!')
            .start()
        }}
      />
      <Info />
      <Social />
    </Card>
  </Layout>
)

export default Home
