import { Home } from 'components'

function App() {
  return (
    <div className='App'>
      <Home />
    </div>
  )
}

export default App
