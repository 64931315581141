import styled from 'styled-components'

export const Link = styled.a``

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`
export const Info = styled.span`
  font-size: 15px;
  text-align: center;
  color: #505050;
`

export const Top = styled.div`
  display: flex;
  justify-content: center;
`
export const Bottom = styled.div`
  display: flex;
  justify-content: center;
`

type IconProps = {
  size?: string
}

export const Icon = styled.svg<IconProps>`
  fill: #505050;
  padding: 10px;
  transition: 0.8s;
  height: ${(props) => (props.size ? props.size : '4.8em')};
  width: ${(props) => (props.size ? props.size : '4.8em')};

  .icon {
    fill: #505050;
    transition: all 0.2s;
  }

  .inner-circle {
    fill: transparent;
    transition: all 0.2s;
  }

  .outline {
    stroke: #505050;
    transform-origin: 50% 50%;
    transition: all 0.2s;
  }

  &:hover,
  &:active,
  &:focus {
    .icon {
      fill: #fffbfa;
      transition: all 0.45s;
    }
    .inner-circle {
      fill: #505050;
      transition: all 0.45s;
    }
    .outline {
      stroke: #505050;
      transform: scale(1.1);
      transition: all 0.45s;
    }
    fill: white;
  }
`
