import * as S from './styles'

const Info = () => {
  const date = new Date()
  return (
    <S.Info>
      I am a {date.getFullYear() - 1997} year old Brazilian and technology
      enthusiast. I have {date.getFullYear() - 2018} years of experience as a
      front-end developer. I have worked with various technologies and cases,
      such as React/React Native, Vuejs, Node, etc.
    </S.Info>
  )
}

export default Info
